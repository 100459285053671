import {
  computed,
  inject,
  Injectable,
  isDevMode,
  OnDestroy,
  signal,
} from '@angular/core';
import {
  filter,
  fromEvent,
  interval,
  map,
  merge,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import {
  SwUpdate,
  UnrecoverableStateEvent,
  VersionEvent,
} from '@angular/service-worker';
import { TranslocoService } from '@jsverse/transloco';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { BuildInfoService } from '@cca-common/core';
import { ErrorLogService } from '@cca-infra/event-management/v1';
import { DOCUMENT } from '@angular/common';
import { AuthenticationFacade } from '@cca-common/authentication';

const checkInterval = 1000 * 30; // 30 seconds

const devMode = isDevMode();

/**
 * SwUpdatesService
 *
 * @description
 * While enabled, this service will:
 * 1. Check for available ServiceWorker updates every 15 seconds.
 * 2. Activate an update as soon as one is available.
 */
@Injectable({
  providedIn: 'root',
})
export class UpdatesService implements OnDestroy {
  // enabled is controlled by the public methods
  private enabled = false;

  // on disable controls current listeners
  private onDisable = new Subject<void>();

  // dependencies, translations/ErrorLog/serviceWorkerUpdate/BuildInfo
  private translocoService = inject(TranslocoService);
  private logService = inject(ErrorLogService);
  private swu = inject(SwUpdate);
  private buildInfo = inject(BuildInfoService);

  // Observable that emits true/false when
  private document = inject(DOCUMENT);
  private documentVisibility = fromEvent(
    this.document,
    'visibilitychange',
  ).pipe(map(() => this.document.hidden));
  private documentHidden = this.documentVisibility.pipe(
    // filter away emission where hidden = false, making this a observable whenever we are hidden
    filter((hidden) => hidden === true),
  );
  private listenerStopper = merge(this.onDisable, this.documentHidden);

  // username
  private authentication = inject(AuthenticationFacade);
  private userName = devMode
    ? computed(() => this.authentication.user()?.userDetail?.userName ?? '')
    : signal('');

  /**
   * public method for enabling listening to updates
   */
  enable() {
    // if sw is not enabled or we already enabled the listeners do nothing
    if (!this.swu.isEnabled || this.enabled) {
      return;
    }
    this.enabled = true;
    this.activateListeners();

    this.documentVisibility.pipe(takeUntil(this.onDisable)).subscribe(() => {
      if (!this.document.hidden && this.enabled) {
        this.activateListeners();
      }
    });
  }

  /**
   * public method for disabling listening
   */
  disable() {
    this.enabled = false;
    this.deactivateListeners();
  }

  /**
   * @internal
   *
   * activates listeners, these listeners will stop when this.onDisable or this.documentHidden will emit
   */
  private activateListeners() {
    const updateInterval = interval(checkInterval);
    let updateIntervalListener: Subscription | null = updateInterval
      .pipe(takeUntil(this.listenerStopper))
      .subscribe(() => {
        this.swu.checkForUpdate().catch(() => location.reload());
      });
    let updateTry = 0;

    // Handler for version update events
    this.swu.versionUpdates.pipe(takeUntil(this.listenerStopper)).subscribe({
      next: (event: VersionEvent) => {
        switch (event.type) {
          // No new version detected
          // case 'NO_NEW_VERSION_DETECTED':
          //   {
          //     console.log('No new Version');
          //   }
          //   break;

          // // Version has been detected and sw will start downloading the new version.
          // case 'VERSION_DETECTED':
          //   {
          //     console.log('New Version has been detected');
          //   }
          //   break;

          // Version is ready to be activated ( has been downloaded, sw will start serving new clients with new version)
          case 'VERSION_READY':
            {
              console.info('New Version is ready');
              this.disable();
              this.showMessageAndReload(t('web.update.available'));
            }
            break;
          case 'VERSION_INSTALLATION_FAILED':
            {
              // disable updateInterval by unsubscribing we remove the interval
              updateIntervalListener?.unsubscribe();
              updateIntervalListener = null;

              // log error
              console.error('New Version installation failed');
              if (updateTry <= 10) {
                updateTry++;
                this.logService
                  .reportError({
                    message: `Service Worker: ${event.type} ${devMode ? `for user ${this.userName() ?? 'unauthorized'}` : ``}`,
                    stack: `Event Data: ${JSON.stringify(event)}`,
                    version: this.buildInfo.getBuildInformation()?.version,
                    sha: this.buildInfo.getBuildInformation()?.sha,
                  })
                  .subscribe();

                // MANUALLY check for updates now
                // check for update, starting at 5 seconds delay increasing the delay
                // 5 10 15 20 25 30
                setTimeout(() => this.swu.checkForUpdate(), 5000 * updateTry);
              } else {
                this.logService
                  .reportError({
                    message: `Service Worker: ${event.type} has failed for more then 10 times ${devMode ? `for user ${this.userName() ?? 'unauthorized'}` : ``}} `,
                    stack: `Event Data: ${JSON.stringify(event)}`,
                    version: this.buildInfo.getBuildInformation()?.version,
                    sha: this.buildInfo.getBuildInformation()?.sha,
                  })
                  .subscribe();
                this.showMessageAndReload(t('web.update.unrecoverableError'));
                this.deactivateListeners();
              }
            }
            break;
        }
      },
    });

    // Request an immediate page reload once an unrecoverable state has been detected.
    this.swu.unrecoverable
      .pipe(
        takeUntil(this.listenerStopper),
        tap((error) =>
          console.error('UNRECOVERABLE ERROR IN SERVICE WORKER', error),
        ),
      )
      .subscribe((event: UnrecoverableStateEvent) => {
        this.disable();
        this.logService
          .reportError({
            message: `Unrecoverable Service Worker State: ${event.reason}`,
            stack: event.reason,
          })
          .subscribe(() => {
            this.showMessageAndReload(t('web.update.unrecoverableError'));
          });
      });
  }

  /**
   * @internal
   * deactivates the listeners, by use of this.onDisable
   */
  private deactivateListeners() {
    this.onDisable.next();
  }

  /**
   * @internal
   */
  private showMessageAndReload(translationKey: string) {
    try {
      this.swu.activateUpdate();
    } catch (e) {
      console.warn('Activating Update failed');
    }

    const message = this.translocoService.translate(translationKey);
    window.alert(message); // this blocks the main thread
    this.fullPageReload();
  }

  /**
   * @internal
   */
  private fullPageReload() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.disable();
  }
}
