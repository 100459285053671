import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  authenticationKey,
  authenticationReducer,
  AuthenticationState,
} from '@cca-common/authentication';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { isNode } from 'browser-or-node';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import { routerKey } from '../../environments/common';

// root state
export interface AppRootState {
  [routerKey]: RouterReducerState;
  [authenticationKey]: AuthenticationState;
}

// action reducer map
export const reducers: ActionReducerMap<AppRootState> = {
  [routerKey]: routerReducer,
  [authenticationKey]: authenticationReducer,
};

// effects
export const effects = [];
export const metaReducers: MetaReducer[] = [
  function localStorageSyncReducer(
    reducer: ActionReducer<unknown>,
  ): ActionReducer<unknown> {
    if (!isNode && environment.store.localStorageKeys) {
      return localStorageSync({
        keys: environment.store.localStorageKeys,
        storage: window.localStorage,
        rehydrate: true,
        restoreDates: false,
      })(reducer);
    } else {
      // no special actions
      return reducer;
    }
  },
];
