import { NgModule } from '@angular/core';

// ngrx modules
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

// imports of environment, effects, metareducers and reducers
import { environment } from '../../environments/environment';
import { effects, metaReducers, reducers } from './store';
import { RouterStoreModule } from '@cca-common/cdk';

@NgModule({
  imports: [
    // ngrx
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: environment.store.strictNgrxRuntimeChecks,
        strictActionImmutability: environment.store.strictNgrxRuntimeChecks,
        strictStateSerializability: environment.store.strictNgrxRuntimeChecks,
        strictActionSerializability: environment.store.strictNgrxRuntimeChecks,
        strictActionWithinNgZone: environment.store.strictNgrxRuntimeChecks,
        strictActionTypeUniqueness: environment.store.strictNgrxRuntimeChecks,
      },
      metaReducers: metaReducers,
    }),

    EffectsModule.forRoot(effects),

    StoreRouterConnectingModule.forRoot(environment.features.router),
    RouterStoreModule.forRoot(),

    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
      trace: !environment.production,
    }),
  ],
})
export class AppStoreModule {}
